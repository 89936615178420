import * as React from 'react';

import {
  AutocompleteInput,
  Create,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  maxLength,
  maxValue,
  minLength,
  minValue,
  required,
  useTranslate,
} from 'react-admin';
import { IWarrantyEquipmentType, IWarrantyStatus } from '../../types';

import { Divider } from '@mui/material';
import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';

const useListStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    marginBottom: 20,
  },
  clientData: {
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    borderStyle: 'solid',
    padding: 15,
    backgroundColor: '#f5f5f5',
  },
  maxFieldWidth: {
    width: '100%',
    maxWidth: 400,
  },
}));

const WarrantiesCreate = () => {
  const translate = useTranslate();
  const classes = useListStyles();

  return (
    <Create>
      <SimpleForm>
        <p>Исполнитель</p>
        <ReferenceInput
          source="clientId"
          reference="customers"
          perPage={10000}
        >
          <AutocompleteInput
            label={'Исполнитель'}
            optionText={(record) =>
              record
                ? `${record.name} ${
                    record.companyName && '(' + record.companyName + ')'
                  } - ${record.phone}`
                : ''
            }
            className={classes.maxFieldWidth}
            validate={[required()]}
          />
        </ReferenceInput>
        <br />
        <Divider />
        <SelectInput
          source="equipmentType"
          label={translate('resources.warranties.fields.equipmentType')}
          className={classes.maxFieldWidth}
          choices={[
            {
              id: IWarrantyEquipmentType.AirConditioner,
              name: translate(
                'resources.warranties.fields.equipmentTypes.air_conditioner'
              ),
            },
            {
              id: IWarrantyEquipmentType.HeatPump,
              name: translate(
                'resources.warranties.fields.equipmentTypes.heat_pump'
              ),
            },
            {
              id: IWarrantyEquipmentType.MultiSplits,
              name: translate(
                'resources.warranties.fields.equipmentTypes.multi_splits'
              ),
            },
          ]}
          validate={[required()]}
        />
        <TextInput
          source="equipmentName"
          label={translate('resources.warranties.fields.equipmentName')}
          // disabled
          className={classes.maxFieldWidth}
        />
        <TextInput
          source="code"
          label={translate('resources.warranties.fields.code')}
          // disabled
          className={classes.maxFieldWidth}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.equipmentType === IWarrantyEquipmentType.AirConditioner ? (
              <TextInput
                source="code2"
                label={translate('resources.warranties.fields.code2')}
                // disabled
                className={classes.maxFieldWidth}
              />
            ) : null
          }
        </FormDataConsumer>
        <Divider />
        <p>Данные клиента</p>
        <div className={classes.container}>
          <div className={`${classes.clientData} ${classes.maxFieldWidth}`}>
          <TextInput
            source="clientData.phoneNumber"
            label={translate('resources.warranties.fields.clientData.phoneNumber')}
            className={classes.maxFieldWidth}
            validate={[required(), maxLength(14), minLength(10)]}
            inputProps={{
              maxLength: 14,
            }}
          />
          <TextInput
            source="clientData.firstName"
            label={translate('resources.warranties.fields.clientData.firstName')}
            className={classes.maxFieldWidth}
            validate={[required(), maxLength(80), minLength(1)]}
            inputProps={{
              maxLength: 80,
            }}
          />
          <TextInput
            source="clientData.lastName"
            label={translate('resources.warranties.fields.clientData.lastName')}
            className={classes.maxFieldWidth}
            validate={[required(), maxLength(80), minLength(1)]}
            inputProps={{
              maxLength: 80,
            }}
          />
          <TextInput
            source="clientData.email"
            label={translate('resources.warranties.fields.clientData.email')}
            className={classes.maxFieldWidth}
            validate={[required(), maxLength(80), minLength(5)]}
            inputProps={{
              maxLength: 80,
            }}
          />
          <TextInput
            source="clientData.address"
            label={translate('resources.warranties.fields.clientData.address')}
            className={classes.maxFieldWidth}
            validate={[maxLength(300), minLength(1)]}
            inputProps={{
              maxLength: 300,
            }}
          />
          </div>
        </div>
        <DateInput
          source="date"
          label={translate('resources.warranties.fields.date')}
          className={classes.maxFieldWidth}
          validate={[required(), minValue('2023-01-01'), maxValue(format(
            new Date(),
            'yyyy-MM-dd'
          ))]}
        />
        <SelectInput
          // disabled
          source="status"
          label={translate('resources.warranties.fields.status')}
          className={classes.maxFieldWidth}
          choices={[
            {
              id: IWarrantyStatus.Active,
              name: translate('resources.warranties.fields.statuses.active'),
            },
            {
              id: IWarrantyStatus.Finished,
              name: translate('resources.warranties.fields.statuses.finished'),
            },
          ]}
          validate={[required()]}
        />
        <NumberInput
          source="warantyPeriod"
          label={translate('resources.warranties.fields.warantyPeriod')}
          min={1}
          max={99}
          step={1}
          defaultValue={24}
          validate={[required()]}
          className={classes.maxFieldWidth}
        />
        <p>Периодичность обслуживания каждые 12 месяцев</p>
      </SimpleForm>
    </Create>
  );
};

export default WarrantiesCreate;
