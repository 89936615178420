import * as React from 'react';

import img_1 from '../assets/icons/1.svg';
import img_10 from '../assets/icons/10.svg';
import img_11 from '../assets/icons/11.svg';
import img_12 from '../assets/icons/12.svg';
import img_13 from '../assets/icons/13.svg';
import img_14 from '../assets/icons/14.svg';
import img_15 from '../assets/icons/15.svg';
import img_16 from '../assets/icons/16.svg';
import img_17 from '../assets/icons/17.svg';
import img_18 from '../assets/icons/18.svg';
import img_2 from '../assets/icons/2.svg';
import img_3 from '../assets/icons/3.svg';
import img_4 from '../assets/icons/4.svg';
import img_5 from '../assets/icons/5.svg';
import img_6 from '../assets/icons/6.svg';
import img_7 from '../assets/icons/7.svg';
import img_8 from '../assets/icons/8.svg';
import img_9 from '../assets/icons/9.svg';

export const images = [
  { img: img_1, id: '1', name: '1' },
  { img: img_2, id: '2', name: '2' },
  { img: img_3, id: '3', name: '3' },
  { img: img_4, id: '4', name: '4' },
  { img: img_5, id: '5', name: '5' },
  { img: img_6, id: '6', name: '6' },
  { img: img_7, id: '7', name: '7' },
  { img: img_8, id: '8', name: '8' },
  { img: img_9, id: '9', name: '9' },
  { img: img_10, id: '10', name: '10' },
  { img: img_11, id: '11', name: '11' },
  { img: img_12, id: '12', name: '12' },
  { img: img_13, id: '13', name: '13' },
  { img: img_14, id: '14', name: '14' },
  { img: img_15, id: '15', name: '15' },
  { img: img_16, id: '16', name: '16' },
  { img: img_17, id: '17', name: '17' },
  { img: img_18, id: '18', name: '18' },
];

export const ImagesField = () => {
  return (
    <div className="items_images_wrap">
      {images.map((item, index) => (
        <div className="items_images_image_wrap" key={index}>
          <img
            src={item.img}
            title={item.name}
            className="items_images_image"
            alt={item.name}
          />
          <div className="items_images_text">{item.name}</div>
        </div>
      ))}
    </div>
  );
};
