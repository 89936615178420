import * as React from 'react';

import {
  ArrayField,
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { IWarranty, IWarrantyEquipmentType, IWarrantyStatus } from '../../types';

import CustomerReferenceField from '../customers/CustomerReferenceField';
import { Divider } from '@mui/material';
import { NestedWarrantyUserField } from '../../components/NestedWarrantyUserField';
import { makeStyles } from '@mui/styles';

const useListStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    marginBottom: 20,
  },
  clientData: {
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 10,
    borderStyle: 'solid',
    padding: 15,
    backgroundColor: '#f5f5f5',
  },
  maxFieldWidth: {
    width: '100%',
    maxWidth: 400,
  },
  recordsContainer: {
    width: '100%',
  },
}));

const Title = () => {
  const record = useRecordContext<IWarranty>();
  const translate = useTranslate();
  return (
    <span>
      {translate('resources.warranties.editText')}{' '}
      {record ? `"${record.code}"` : ''}
    </span>
  );
};

const WarrantiesEdit = () => {
  const translate = useTranslate();
  const classes = useListStyles();

  return (
    <Edit title={<Title />}>
      <SimpleForm>
        <p>Исполнитель</p>
        {/* <CustomerReferenceField label="Клиент" /> */}
        <ReferenceInput
          source="clientId"
          reference="customers"
          perPage={10000}
        >
          <AutocompleteInput
            label={'Исполнитель'}
            optionText={(record) =>
              record
                ? `${record.name} ${
                    record.companyName && '(' + record.companyName + ')'
                  } - ${record.phone}`
                : ''
            }
            className={classes.maxFieldWidth}
            validate={[required()]}
          />
        </ReferenceInput>
        <br />
        <Divider />
        <SelectInput
          source="equipmentType"
          label={translate('resources.warranties.fields.equipmentType')}
          className={classes.maxFieldWidth}
          choices={[
            {
              id: IWarrantyEquipmentType.AirConditioner,
              name: translate(
                'resources.warranties.fields.equipmentTypes.air_conditioner'
              ),
            },
            {
              id: IWarrantyEquipmentType.HeatPump,
              name: translate(
                'resources.warranties.fields.equipmentTypes.heat_pump'
              ),
            },
            {
              id: IWarrantyEquipmentType.MultiSplits,
              name: translate(
                'resources.warranties.fields.equipmentTypes.multi_splits'
              ),
            },
          ]}
          validate={[required()]}
        />
        <TextInput
          source="equipmentName"
          label={translate('resources.warranties.fields.equipmentName')}
          // disabled
          className={classes.maxFieldWidth}
        />
        <TextInput
          source="code"
          label={translate('resources.warranties.fields.code')}
          // disabled
          className={classes.maxFieldWidth}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.equipmentType === IWarrantyEquipmentType.AirConditioner ? (
              <TextInput
                source="code2"
                label={translate('resources.warranties.fields.code2')}
                // disabled
                className={classes.maxFieldWidth}
              />
            ) : null
          }
        </FormDataConsumer>
        <ArrayField source="items">
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" label={translate('resources.warranties.fields.equipmentName')} />
                <TextField source="code" />
                <TextField source="code2" />
            </Datagrid>
        </ArrayField>
        <Divider />
        <p>Данные клиента</p>
        <div className={classes.container}>
          <div className={`${classes.clientData} ${classes.maxFieldWidth}`}>
            <NestedWarrantyUserField
              source="clientData"
              subSource="phoneNumber"
              label={translate(
                'resources.warranties.fields.clientData.phoneNumber'
              )}
            />
            <br />
            <NestedWarrantyUserField
              source="clientData"
              subSource="firstName"
              label={translate(
                'resources.warranties.fields.clientData.firstName'
              )}
            />
            <br />
            <NestedWarrantyUserField
              source="clientData"
              subSource="lastName"
              label={translate(
                'resources.warranties.fields.clientData.lastName'
              )}
            />
            <br />
            <NestedWarrantyUserField
              source="clientData"
              subSource="email"
              label={translate('resources.warranties.fields.clientData.email')}
            />
            <br />
            <NestedWarrantyUserField
              source="clientData"
              subSource="address"
              label={translate(
                'resources.warranties.fields.clientData.address'
              )}
            />
          </div>
        </div>
        <DateInput
          source="date"
          label={translate('resources.warranties.fields.date')}
          disabled
          className={classes.maxFieldWidth}
        />
        <DateInput
          source="nextDate"
          label={translate('resources.warranties.fields.nextDate')}
          disabled
          className={classes.maxFieldWidth}
        />
        <SelectInput
          // disabled
          source="status"
          label={translate('resources.warranties.fields.status')}
          className={classes.maxFieldWidth}
          choices={[
            {
              id: IWarrantyStatus.Active,
              name: translate('resources.warranties.fields.statuses.active'),
            },
            {
              id: IWarrantyStatus.Finished,
              name: translate('resources.warranties.fields.statuses.finished'),
            },
          ]}
          validate={[required()]}
        />
        <NumberInput
          source="warantyPeriod"
          label={translate('resources.warranties.fields.warantyPeriod')}
          min={1}
          max={99}
          step={1}
          defaultValue={24}
          validate={[required()]}
          className={classes.maxFieldWidth}
        />
        <p>Периодичность обслуживания каждые 12 месяцев</p>
        <br/>
        {/* <p>Записи об обслуживании:</p> */}
        <div className={classes.recordsContainer}>
          <ArrayField source="records" fullWidth>
              <Datagrid bulkActionButtons={false}>
                  <TextField source="text" label="Описание" />
                  <BooleanField source="isRegular" label="Регулярное" />
                  <DateField showTime source="date" label="Дата" />
              </Datagrid>
          </ArrayField>
        </div>
      </SimpleForm>
    </Edit>
  );
};

export default WarrantiesEdit;
