import * as React from 'react';

import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  SelectField,
  TextField,
  useTranslate,
} from 'react-admin';
import { IWarrantyEquipmentType, IWarrantyStatus } from '../../types';
import { Theme, useMediaQuery } from '@mui/material';

import CustomerReferenceField from '../customers/CustomerReferenceField';
import MobileGrid from './MobileGrid';

// const filters = [<SearchInput source="q" alwaysOn />];
const WarrantyFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput
      source="client_id"
      reference="customers"
      label="Исполнитель"
      perPage={10000}
    >
      <AutocompleteInput
        label="Исполнитель"
        optionText={(choice) =>
          choice.id // the empty choice is { id: '' }
            ? `${choice.name} (${choice.companyName})`
            : ''
        }
      />
    </ReferenceInput>
    <DateInput source="date_gte" label="Дата от" />
    <DateInput source="date_lte" label="Дата до" />
  </Filter>
);

const WarrantiesList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const translate = useTranslate();

  return (
    <List
      title={translate('pos.menu.warranties')}
      // filters={!isSmall ? filters : undefined}
      filters={<WarrantyFilter />}
      sort={{ field: 'signUpDate', order: 'DESC' }}
      perPage={25}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid rowClick="edit">
          <CustomerReferenceField
            label={translate('resources.warranties.fields.clientId')}
          />
          <SelectField
            source="equipmentType"
            label={translate('resources.warranties.fields.equipmentType')}
            choices={[
              {
                id: IWarrantyEquipmentType.AirConditioner,
                name: translate(
                  'resources.warranties.fields.equipmentTypes.air_conditioner'
                ),
              },
              {
                id: IWarrantyEquipmentType.HeatPump,
                name: translate(
                  'resources.warranties.fields.equipmentTypes.heat_pump'
                ),
              },
              {
                id: IWarrantyEquipmentType.MultiSplits,
                name: translate(
                  'resources.warranties.fields.equipmentTypes.multi_splits'
                ),
              },
            ]}
          />
          <TextField
            source="equipmentName"
            label={translate('resources.warranties.fields.equipmentName')}
          />
          <TextField
            source="code"
            label={translate('resources.warranties.fields.code')}
          />
          {/* <NestedWarrantyUserField
            source="clientData"
            subSource="phoneNumber"
            label={translate(
              'resources.warranties.fields.clientData.phoneNumber'
            )}
          /> */}
          <DateField
            source="date"
            label={translate('resources.warranties.fields.date')}
            showTime
          />
          <DateField
            source="nextDate"
            label={translate('resources.warranties.fields.nextDate')}
            showTime
          />
          <SelectField
            source="status"
            label={translate('resources.warranties.fields.status')}
            choices={[
              {
                id: IWarrantyStatus.Active,
                name: translate('resources.warranties.fields.statuses.active'),
              },
              {
                id: IWarrantyStatus.Finished,
                name: translate(
                  'resources.warranties.fields.statuses.finished'
                ),
              },
            ]}
          />
        </Datagrid>
      )}
    </List>
  );
};

export default WarrantiesList;
